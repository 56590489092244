/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: local('Lato Regular'), local('Lato-Regular'), url(https://fonts.gstatic.com/s/lato/v13/UyBMtLsHKBKXelqf4x7VRQ.woff2) format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: local('Lato Regular'), local('Lato-Regular'), url(https://fonts.gstatic.com/s/lato/v13/1YwB1sO8YE1Lyjf12WNiUA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

body {
  font-family: 'Lato', sans-serif;
}

/* Sovelluksen tyylit */
.nav-left {
  height: 128px;
}

.nav-item img {
  max-height: 80px;
}

.block-right {
  text-align: right;
}

.block-right-btn {
  margin-left: 8px;
}

.justify-text {
  text-align: justify;
}

.image-inline {
  display: inline-block;
  margin: 32px;
}
.logo-footer1 img {
  min-width: 165px;

}
.logo-footer1 {
  top: 9px;
  right: 30px;
}

.logo-footer2 {
  top: 10px;
}

.logo-footer3 {
  top: 14px;
}

/* Tooltip container */
.tooltip {
  color: #005f3b;
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  width: 256px;
  background-color: #005f3b;
  color: #fff;
  text-align: left;
  padding: 8px;
  border-radius: 3px;
  position: absolute;
  top: -5px;
  left: 110%;
  z-index: 1;
}

.text-long {
  font-size: 13px;
}

/* Required field tooltip container */
.reqtooltip {
  color: #b45a43;
  position: relative;
  display: inline-block;
}
.padding {
  padding-bottom: 20px;
}
/* Required field tooltip text */
.reqtooltip .tooltiptext {
  width: 256px;
  background-color: #b45a43;
  color: #fff;
  text-align: left;
  padding: 8px;
  border-radius: 3px;
  position: absolute;
  top: -5px;
  left: 110%;
  z-index: 1;
}

.field-nowrap {
  white-space: nowrap;
  flex-wrap: nowrap;
}

.checkbox-label {
  margin-top: 5px;
}

strong {
  white-space: pre-wrap;
}

/* Metsäkeskuksen tyylit Bulman päälle */
.hero.is-primary {
  /*background: repeating-linear-gradient(
    -45deg,
    #167d3f,
    #167d3f 10px,
    #005f3b 10px,
    #005f3b 20px
  );*/
  background-color: #005f3b;
  color: #fff;
}

.button.is-success {
  background-color: #0B7C3E;
  color: #fff;
}

.button.is-success:hover, .button.is-success:active {
  background-color: #09592D;
}

.button.is-danger {
  background-color: #B45A43;
  color: #fff;
}

.button.is-danger:hover, .button.is-danger:active {
  background-color: #813C2B;
  color: #fff;
}

.notification {
  padding: 8px;
}

.notification.is-danger {
  background-color: #b45a43;
  color: #fff;
}

.notification.is-notification {
  background-color: transparent;
}

.Select-control {
  display: block !important;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: none;
  border-radius: 3px;
  box-shadow: none;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 1rem;
  height: 2.285em;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  line-height: 1.5;
  position: relative;
  vertical-align: top;
  background-color: white;
  border: 1px solid #868991;
  color: #868991;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  max-width: 100%;
  width: 100%;
}

.input {
  border: 1px solid #868991;
  border-radius: 3px;
  box-shadow: none;
}

input:active, input:focus, button:active, button:focus{
  border-color: #007eff !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1);
  background: #fff;
}

::-webkit-input-placeholder {
  color:#868991;
}

::-moz-placeholder {
  color:#868991;
}

::-ms-placeholder {
  color:#868991;
}

::placeholder {
  color:#868991;
}


.notification-mun {
  margin-top: 0.75em;
}

.container .heading .title {
  margin-bottom: 32px;
  text-transform: none;
  color: #167d3f;
}

.input[disabled], .input.is-disabled, .textarea[disabled], .textarea.is-disabled {
  background-color: whitesmoke;
  border-color: whitesmoke;
}

.nav-item-lang-select {
  padding-right: 0px;
}

.lang-select {
  width: 128px;
  text-align: left;
}

a {
  text-decoration: underline;
}

label {
  font-weight: 700;
  color: #363636;
}

.unstyledButton {
  all: unset;
  display: inline;
  cursor: pointer;
}

.estate-grid-header {
  margin-bottom: 1rem;
}

.mt-50-p {
  margin-top: 50%;
}

.divider {
  height: 1px;
  background-color: #363636;
  width: 100%;
  margin: 2rem 0;
}